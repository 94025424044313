import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { InfoBox } from '@components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "infobox"
    }}>{`InfoBox`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={InfoBox} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<InfoBox\n  title=\"Help  us improve TheFans!\"\n  description=\"Send us feedback and features you’d like to see in the future.\"\n  iconName=\"user-help\"\n  onPress={() => {}}\n  theme=\"dark\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      InfoBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <InfoBox title="Help  us improve TheFans!" description="Send us feedback and features you’d like to see in the future." iconName="user-help" onPress={() => {}} theme="dark" mdxType="InfoBox" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      